import axios from "axios";
import Cookies from "js-cookie";
const baseURL = "https://copilot2.leastcube.com";
// const baseURL = "https://copilot.leastcube.com";

// const baseURL = "http://localhost:8004/api/copilot";
const api = axios.create({
  baseURL,
  timeout: 600000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const apiEndpoints = {
  loadConversation: "/api/copilot/conversation",
  sendMessage: "/api/copilot/message",
  delete: "/api/copilot/delete",
  getUsers: "/api/users",
  getUser: "/api/users/get_user",
  getContents: "/api/copilot/contents",
  getSessionContents: "/api/copilot/sessions",
  newLoadConversation: "/api/conversation",
  newSendMessage: "/api/message",
  interactive: "/api/copilot/interactive",
  getUserSessionId: "/api/copilot/get_user_sessionId",
  createSession: "/api/whisper/create_session",
  getInsight: "/api/whisper/get_insight",
  postFeedback: "/api/whisper/feedback",
};

export const postUserMessage = async (
  message,
  memberUsername,
  memberPassword,
  isComment
) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");

  const payload = {
    content: message,
    username: memberUsername,
    password: memberPassword,
    appUsername: username,
    appPassword: password,
  };

  if (isComment) {
    payload.is_comment = true;
  }

  try {
    const response = await api.post(apiEndpoints.sendMessage, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting user message:", error);
    throw error;
  }
};

export const loadMessages = async (
  memberUsername,
  memberPassword,
  verified
) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");

  let payload = {
    username: memberUsername,
    password: memberPassword,
    appUsername: username,
    appPassword: password,
  };

  if (verified) {
    payload.verified = true;
  }

  try {
    const response = await api.post(apiEndpoints.loadConversation, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error loading user messages:", error);
    throw error;
  }
};

export const deleteMessages = async (memberUsername, memberPassword) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.post(
      apiEndpoints.delete,
      {
        username: memberUsername,
        password: memberPassword,
        appUsername: username,
        appPassword: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user message:", error);
    throw error;
  }
};

export const loadBotData = async (username, password) => {
  try {
    const response = await api.get(
      `/api/copilot?appUsername=${username}&appPassword=${password}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error loading bot information:", error);
    throw error;
  }
};

export const getUsers = async () => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.get(
      `/api/users?appUsername=${username}&appPassword=${password}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error loading bot information:", error);
    throw error;
  }
};

export const getUser = async (memberUsername, memberPassword, debugging) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.post(
      apiEndpoints.getUser,
      {
        username: memberUsername,
        password: memberPassword,
        appUsername: username,
        appPassword: password,
        debugging: debugging,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error loading bot information:", error);
    throw error;
  }
};

export const getContents = async (contentIds) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.get(
      apiEndpoints.getContents,
      {
        params: {
          appUsername: username,
          appPassword: password,
          contents_id: contentIds,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error loading content Ids:", error);
    throw error;
  }
};

export const getSessionContents = async (contentIds) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.get(
      apiEndpoints.getSessionContents,
      {
        params: {
          appUsername: username,
          appPassword: password,
          session_ids: contentIds,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error loading content Ids:", error);
    throw error;
  }
};

export const getTestUsers = async () => {
  try {
    return await axios.get("https://nexgeneap.com/api/copilot/getTestUsers");
  } catch (error) {
    console.error("Error loading test users: ", error);
    throw error;
  }
};

export const getTestUserInformation = async (userid) => {
  try {
    return await axios.get(
      "https://nexgeneap.com/api/copilot/GetUserInfoForCopilot",
      {
        params: {
          userId: userid,
        },
      }
    );
  } catch (error) {
    console.error("Error loading test users: ", error);
    throw error;
  }
};

export const loadMessagesNew = async (message, memberUsername) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  message.password = memberUsername;
  let payload = {
    message: message,
    appUsername: username,
    appPassword: password,
  };

  try {
    const response = await api.post(apiEndpoints.newLoadConversation, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error loading user messages:", error);
    throw error;
  }
};

export const postUserMessageNew = async (message, sessionId) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");

  const payload = {
    copilot_model: "copilot",
    sessionid: sessionId,
    message_new: message,
    appUsername: username,
    appPassword: password,
  };

  try {
    const response = await api.post(apiEndpoints.newSendMessage, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting user message:", error);
    throw error;
  }
};

export const interactive = async (username, password, interactive) => {
  const data = {
    username: username,
    password: password,
    Interactive: interactive,
    appUsername: Cookies.get("username"),
    appPassword: Cookies.get("password"),
  };
  console.log(interactive);
  return await api.post(apiEndpoints.interactive, data, {
    headers: {
      "Content-Type": "application/json",
    },
    auth: {
      username: Cookies.get("username"),
      password: Cookies.get("password"),
    },
  });
};

export const interactiveNew = async (interactive, sessionId) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");

  const payload = {
    copilot_model: "copilot",
    sessionid: sessionId,
    message_new: "",
    Interactive: interactive,
    appUsername: username,
    appPassword: password,
  };

  try {
    const response = await api.post(apiEndpoints.newSendMessage, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    });
    return response;
  } catch (error) {
    console.error("Error posting user message:", error);
    throw error;
  }
};

export const getUserSessionId = async (sessionId, debugging) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  return await api.post(
    apiEndpoints.getUserSessionId,
    {
      appUsername: username,
      appPassword: password,
      sessionId: sessionId,
      debugging: debugging,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    }
  );
};

export const createSession = async (userData) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  return await api.post(
    apiEndpoints.createSession,
    {
      ...userData,
      appUsername: username,
      appPassword: password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    }
  );
};

export const getInsight = async (conversation, session_id) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  return await api.post(
    apiEndpoints.getInsight,
    {
      conversation,
      session_id,
      appUsername: username,
      appPassword: password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: username,
        password: password,
      },
    }
  );
};

export const postFeedback = async (
  session_id,
  conversation,
  whisper_result,
  feedback
) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  return await api.post(
    apiEndpoints.postFeedback,
    {
      session_id,
      conversation,
      whisper_result,
      feedback,
      appUsername: username,
      appPassword: password,
    },
    {
      headers: { "Content-Type": "application/json" },
      auth: { username: username, password: password },
    }
  );
};
